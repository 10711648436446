import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/layout'),
    children: [
      {
        path: '', //默认子路由，只能有1个
        name: 'home',
        component: () => import('@/views/home')
      },
      {
        path: '/category',
        name: 'category',
        component: () => import('@/views/category')
      },
      {
        path: '/mine',
        name: 'mine',
        component: () => import('@/views/mine')
      },
    ]
  },
  {
    path: '/play',
    name: 'play',
    component: () => import('@/views/play')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
